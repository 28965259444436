import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Button from 'common/Button';
import GatsbyImage from 'common/GatsbyImage';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import useMatchMedia from 'hooks/useMatchMedia';
import { DESKTOP_BREAKPOINT } from 'utils/constants';

import { ICompetitionBannerProps } from './model';

import './CompetitionBanner.scss';

const CompetitionBanner: FC<ICompetitionBannerProps> = ({
  title,
  description,
  links,
  backgroundImage,
  mobileImage,
  footnote,
}) => {
  const isDesktop = useMatchMedia(`(min-width: ${DESKTOP_BREAKPOINT}px)`);

  return (
    <div className="competition-banner">
      <div className="competition-banner__container competition-banner__container--top-content">
        <DangerouslySetInnerHtml className="competition-banner__title" html={title} />
        <DangerouslySetInnerHtml className="competition-banner__description" html={description} />
        {links?.length ? (
          <ul className="competition-banner__links">
            {links.map(
              (
                {
                  properties: {
                    link: [{ name, url }],
                    ariaLabel,
                  },
                },
                idx
              ) => (
                <li key={url}>
                  <Button
                    to={url}
                    ariaLabel={ariaLabel}
                    variant={idx === 0 ? 'primary-link' : 'secondary-link'}
                    iconSuffix="chevron-right"
                  >
                    {name}
                  </Button>
                </li>
              )
            )}
          </ul>
        ) : null}
      </div>
      {!isDesktop ? (
        <div className="competition-banner__mobile-block">
          <GatsbyImage
            className="competition-banner__mobile-block-image"
            image={mobileImage[0].properties.image}
            alt={mobileImage[0].properties.imageAlt}
            isLazyLoading={false}
          />
        </div>
      ) : null}
      <div className="competition-banner__footnote-wrapper">
        <div className="competition-banner__container competition-banner__container--bottom-content">
          <DangerouslySetInnerHtml className="competition-banner__footnote" html={footnote} />
        </div>
      </div>
      {isDesktop ? (
        <div className="competition-banner__background">
          <GatsbyImage
            className="competition-banner__background-image"
            image={backgroundImage[0].properties.image}
            alt={backgroundImage[0].properties.imageAlt}
            isLazyLoading={false}
          />
        </div>
      ) : null}
    </div>
  );
};

export const query = graphql`
  fragment FragmentCompetitionBanner on TCompetitionBanner {
    properties {
      title
      description
      footnote
      links {
        properties {
          ariaLabel
          link {
            name
            url
          }
        }
      }
      backgroundImage {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 2400, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          imageAlt
        }
      }
      mobileImage {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 990, quality: 90, srcSetBreakpoints: [340, 720]) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          imageAlt
        }
      }
    }
    structure
  }
`;

export default CompetitionBanner;
