const settings = {
  className: 'center',
  centerMode: true,
  adaptiveHeight: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  infinite: true,
  speed: 500,
  responsive: [
    {
      breakpoint: 2500,
      settings: {
        slidesToShow: 3,
        centerMode: false,
      },
    },

    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2,
        centerMode: false,
      },
    },

    {
      breakpoint: 650,
      settings: {
        centerPadding: '20%',
      },
    },

    {
      breakpoint: 550,
      settings: {
        centerPadding: '14%',
      },
    },

    {
      breakpoint: 470,
      settings: {
        centerPadding: '10%',
      },
    },

    {
      breakpoint: 420,
      settings: {
        centerPadding: '5%',
      },
    },
  ],
};
export default settings;
