import React, { FC } from 'react';
import { graphql } from 'gatsby';
import classnames from 'classnames';

import { Container } from 'layout';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import WithCarousel from 'components/WithCarousel';

import ConnectItem from './ConnectItem';
import settings from './constants';
import { ICommunityConnectSectionProps } from './model';

import './CommunityConnectSection.scss';

const CommunityConnectSection: FC<ICommunityConnectSectionProps> = ({
  title,
  bgColor,
  connections,
  waiSettings,
}) => {
  const adjustedConnections = connections?.map((connection) => ({ ...connection?.properties }));

  return (
    <section
      className={classnames('community-connect', {
        [`community-connect--bg-${bgColor?.label}`]: bgColor?.label,
      })}
    >
      <Container fluid>
        <DangerouslySetInnerHtml className="community-connect__title" html={title} />
        <WithCarousel
          ariaNext={waiSettings.ariaNext}
          ariaPrev={waiSettings.ariaPrev}
          slideComponent={ConnectItem}
          slides={adjustedConnections}
          settings={settings}
        />
      </Container>
    </section>
  );
};

export const query = graphql`
  fragment FragmentCommunityConnectSection on TCommunityConnectSection {
    properties {
      title
      bgColor {
        label
        value
      }
      connections {
        properties {
          image {
            properties {
              image {
                ...FragmentGatsbyProps
                gatsbyImage {
                  childImageSharp {
                    fluid(maxWidth: 350, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              imageAlt
            }
          }
          avatar {
            properties {
              image {
                ...FragmentGatsbyProps
                gatsbyImage {
                  childImageSharp {
                    fluid(maxWidth: 50) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              imageAlt
            }
          }
          link {
            name
            url
            target
          }
        }
      }
    }
    structure
  }
`;

export default CommunityConnectSection;
