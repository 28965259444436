import React, { FC } from 'react';
import classnames from 'classnames';
import './QuestionsBlock.scss';
import { QuestionsBlockProps } from './model';
import QuestionCard from '../QuestionCard';

const QuestionsBlock: FC<QuestionsBlockProps> = ({ questionCards, isVerticalCardMode }) => (
  <div
    className={classnames('questions-block-grid', {
      'questions-block-grid--vertical': isVerticalCardMode,
    })}
  >
    {questionCards.map((item) => {
      const { title, image, link, description } = item;

      return (
        <QuestionCard
          key={item.title}
          title={title}
          image={image}
          link={link}
          description={description}
          isVerticalCardMode={isVerticalCardMode}
        />
      );
    })}
  </div>
);

export default QuestionsBlock;
