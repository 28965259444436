import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { ISectionWithCarouselProps } from '@shared/types';
import { Container, DangerouslySetInnerHtml } from 'layout';
import './SectionWithCarousel.scss';
import classNames from 'classnames';
import Button from '../../common/Button';

const SectionWithCarousel: FC<ISectionWithCarouselProps> = ({
  text,
  color,
  cardsBlock,
  button,
}) => (
  <div
    className={classNames('section-with-carousel', {
      [`section-with-carousel--${color?.label}-theme`]: color?.label,
    })}
  >
    <Container fluid>
      <DangerouslySetInnerHtml html={text} className="section-with-carousel__text-block" />
      {cardsBlock}
      {button?.length > 0 ? (
        <div className="section-with-carousel__button-container">
          <Button
            variant="secondary"
            ariaLabel={button[0]?.properties?.ariaLabel}
            to={button[0]?.properties?.link?.[0]?.url}
          >
            {button[0]?.properties?.ariaLabel}
          </Button>
        </div>
      ) : null}
    </Container>
  </div>
);

export default SectionWithCarousel;

export const query = graphql`
  fragment FragmentSectionWithCarousel on TSectionwithcarousel {
    properties {
      text
      carouselMode
      verticalCardMode
      fullwidthCardMode
      button {
        properties {
          ariaLabel
          link {
            name
            target
            url
          }
        }
      }
      color {
        label
      }
      card {
        properties {
          title
          image {
            properties {
              imageAlt
              image {
                ...FragmentGatsbyProps
                gatsbyImage {
                  childImageSharp {
                    fluid(maxWidth: 265) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          link {
            url
          }
          description
        }
        structure
      }
    }
    structure
  }
`;
