import React, { FC } from 'react';
import Slider from 'react-slick';

import Button from 'common/Button';
import Icon from 'common/IconCustom';
import FeatureBanner from 'components/FeatureBanner';

import { IWithCarouselProps, ICarouselControlProps } from './model';
import { DefaultCarouselSettings } from './constants';

import 'components/PDCarousel/PDCarousel.scss';
import 'styles/common/slick-styles.scss';
import './WithCarousel.scss';

const CarouselControl = ({
  className,
  classes,
  onClick,
  ariaLabel,
  iconName,
}: ICarouselControlProps) => (
  <Button
    classes={classes}
    ariaLabel={ariaLabel}
    clickHandler={onClick}
    variant="secondary"
    disabled={className.includes('disabled')}
  >
    <Icon icon={iconName} />
  </Button>
);

const WithCarousel: FC<IWithCarouselProps> = ({
  slides,
  settings = {},
  slideComponent: SlideComponent,
  ariaNext,
  ariaPrev,
  isRtl,
}) => {
  if (slides?.length > 1) {
    const defaultSettings = {
      ...DefaultCarouselSettings,
      prevArrow: (
        <CarouselControl ariaLabel={ariaPrev} iconName="chevron-left" classes="prev-btn" />
      ),
      nextArrow: (
        <CarouselControl ariaLabel={ariaNext} iconName="chevron-right" classes="next-btn" />
      ),
      customPaging: () => <button type="button" aria-label={ariaNext} className="dot-btn" />,
    };

    const slideComponents = {
      'Feature Banner': FeatureBanner,
      default: SlideComponent,
    };

    return (
      <div className="carousel-wrapper" data-testid="WithCarousel">
        <Slider {...{ ...defaultSettings, ...settings }}>
          {slides.map((item, idx) => {
            const Slide = slideComponents[item.structure] || slideComponents.default;

            return <Slide {...item} key={idx} isLazyLoading={!!idx} isRtl={isRtl} />;
          })}
        </Slider>
      </div>
    );
  }

  return <SlideComponent {...slides?.[0]} isRtl={isRtl} />;
};

export default WithCarousel;
