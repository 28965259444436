import React, { FC } from 'react';

import GatsbyImage from 'common/GatsbyImage';
import ExternalLink from 'common/ExternalLink';

import { IConnectItemProps } from './model';

const ConnectItem: FC<IConnectItemProps> = ({ image, avatar, link }) => (
  <ExternalLink link={link?.[0]?.url} target={link?.[0].target} className="community-connect__item">
    <GatsbyImage image={image?.[0]?.properties?.image} alt={image?.[0]?.properties?.imageAlt} />
    <div className="community-connect__item-connection">
      <GatsbyImage
        image={avatar?.[0]?.properties?.image}
        alt={avatar?.[0]?.properties?.imageAlt}
        className="community-connect__item-avatar"
      />
      <p className="community-connect__item-nickname">{link?.[0]?.name}</p>
    </div>
  </ExternalLink>
);

export default ConnectItem;
